import { phoneValidator, nameValidator } from '@/utils/validate'
import { generateColLayout } from '@/utils/util'
// import Validator from '@/validators'
export const baseFormConfig = {
  formItems: [
    // {
    //   field: 'avatarUrl',
    //   type: 'imgUpload',
    //   label: 'profile.profile-user-avatar',
    //   uploadTip: 'profile.profile-avatar-tip',
    //   otherOptions: {
    //     uploadApiAction: 'system/uploadImgFile'
    //   },
    //   colLayout: generateColLayout(24)
    // },
    // {
    //   field: 'preference',
    //   type: 'imgUpload',
    //   label: 'profile.profile-user-avatar',
    //   uploadType: 'avatar',
    //   uploadTip: 'Please upload your avatar',
    //   colLayout: generateColLayout(24)
    // },
    {
      field: 'userName',
      type: 'input',
      label: 'general.username',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'firstName',
      type: 'input',
      label: 'user.first-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'middleName',
      type: 'input',
      label: 'user.middle-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'lastName',
      type: 'input',
      label: 'user.last-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email',
      otherOptions: {
        maxlength: 255
      }
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone',
      otherOptions: {
        maxlength: 18
      }
    },
    {
      field: 'address',
      type: 'input',
      label: 'user.address',
      otherOptions: {
        maxlength: 300
      },
      colLayout: generateColLayout(24)
    }
  ],
  validateRules: {
    firstName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
      // {
      //   validator: Validator.regex,
      //   regex: /^[A-Za-z]*$/
      // }
    ],
    middleName: [
      { validator: nameValidator, trigger: 'change' }
      // {
      //   validator: Validator.regex,
      //   regex: /^[A-Za-z]*$/
      // }
    ],
    lastName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
      // {
      //   validator: Validator.regex,
      //   regex: /^[A-Za-z]*$/
      // }
    ],
    email: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { type: 'email', message: 'general.enter-email-message' }
    ],
    mobile: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: phoneValidator, trigger: 'change' }
    ]
  }
}
